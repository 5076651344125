<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <h3>'{{ humanBookTitle }}' Covers</h3>
          <p>Different covers are available on these books. Click to see them on Amazon.</p>
          <br />
        </div>
        <!-- End Left -->
      </div>
    </div>

    <SocialShare
      :page-url="currentAbsoluteRoute"
    />

    <!-- End tokyo_tm_title -->
    <PortfolioImageList
      :images="bookVersionImages"
    />
  </div>
</template>

<script>
  let ROOT_PATH = 'https://www.toasterartist.com'
  import PortfolioImageList from '../components/PortfolioImageList'
  import SocialShare from '../components/SocialShare'
  import { shuffle, startCase, includes } from 'lodash'

  export default {
    components: {
      PortfolioImageList,
      SocialShare,
    },
    metaInfo() {
      return {
        title: 'Toaster Artist | ' + this.humanBookTitle,
        meta: [
            // Twitter Card
            {name: 'twitter:card', content: 'summary'},
            {name: 'twitter:title', content: 'Toaster Artist | ' + this.humanBookTitle},
            {name: 'twitter:description', content: 'Various versions available.'},
            // image must be an absolute path
            {name: 'twitter:image', content: this.metaImage},
            // Facebook OpenGraph
            {property: 'og:title', content: 'Toaster Artist | ' + this.humanBookTitle},
            {property: 'og:site_name', content: 'Toaster Artist'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content:  this.metaImage},
            {property: 'og:description', content: 'Various versions available.'},
        ]
      }
    },
    data() {
      return {
        metaImage: ROOT_PATH + require('../assets/img/logo/logo.png'),
        bookTitle: this.$route.params.title,
        index: null,
        firstAsin: this.$route.query.asin,
      };
    },
    computed: {
      bookVersionImages() {
        const requiredContext = require.context('../assets/img/books/', true, /[A-Z|0-9]+\.jpg$/)
        let images = this.importImages(requiredContext)
        let firstImage = images.shift()
        images = shuffle(images)
        images.unshift(firstImage)

        return images
      },
      humanBookTitle() {
        return startCase(this.bookTitle)
      },
      currentAbsoluteRoute() {
        return "http://toasterartist.com" + this.$route.path
      },
    },
    methods: {
      importImages(requiredContext) {
        let images = []
        const regexString = `\\.\\/${this.bookTitle}\\/.*\\.jpg`

        requiredContext.keys().map(key => {
          if (key.match(regexString)) {
            let path = requiredContext(key)

            if (includes(key, this.firstAsin)) {
              images.unshift({ src: path, thumb: path })
            } else {
              images.push({ src: path, thumb: path })
            }
          }
        })

        return images
      },
    },
    watch: {
      $route(to,) {
        this.bookTitle = to.params.title
        this.firstAsin = to.query.asin
        this.metaImage = ROOT_PATH + this.bookVersionImages[0].src
      }
    },
  };
</script>

<style lang="scss" scoped>
  .social_share_buutons {
    padding-bottom: 25px;
  }
</style>
