<template>
  <div class="tabcontent">
    <ul class="portfolio_list">
      <li
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="index = imageIndex"
      >
        <div class="inner">
          <div v-if="isBookListImages">
            <router-link :to="webLink(image)">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image parent-img-responsive" :src="image.thumb" alt="Portfolio" />
              </div>
            </router-link>
          </div>
          <div v-else>
            <a :href="webLink(image)" target="_blank">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <div class="parent-img">
                  <img class="image parent-img-responsive" :src="image.thumb" alt="Portfolio" />
                  <img class="child-img" :src="amz_buy_now_img" alt="Buy Now Amazon" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </li>
      <!-- End li -->
    </ul>
    <!-- End .portfolio_list -->
  </div>
</template>

<script>
  import { split } from 'lodash'

  export default {
    data() {
      return {
        index: null,
        amz_buy_now_img: require("../assets/img/marketplace/buy_now_amz.png"),
      }
    },
    props: {
      images: {
        Array,
        default() {
          []
        }
      },
      imageType: {
        Boolean,
        default: 'BookVersion'
      }
    },
    computed: {
      isBookVersionImages() {
        return this.imageType === 'BookVersion'
      },
      isBookListImages() {
        return this.imageType === 'BookList'
      },
      isArtImages() {
        return this.imageType === 'Art'
      }
    },
    methods: {
      webLink(image) {
        let asin = ''

        // '/img/gratitude-journal.51e5abde.jpg' -> '/img/gratitude-journal'
        // '/img/B09918LNJG.51e5abde.jpg' -> '/img/B09918LNJG'
        let extensionlessPath = split(image['src'], ".")[0]
        // '/img/gratitude-journal' -> 'gratitude-journal'
        // '/img/B09918LNJG' -> 'B09918LNJG'
        let titleAsin = split(extensionlessPath, "/")[split(extensionlessPath, "/").length - 1]
        let title = split(titleAsin, "_")[0]

        if (split(titleAsin, "_")[split(titleAsin, "_").length - 1] != title) {
          asin = split(titleAsin, "_")[split(titleAsin, "_").length - 1]
        }

        const urlHelpers = {
          BookList: { baseUrl: '/book-versions/', queryParam: '?asin=' + asin },
          BookVersion: { baseUrl: 'https://www.amazon.com/dp/', queryParam: '?tag=tqwd-20' },
        }

        const urlHelper = urlHelpers[this.imageType]
        const urlBody = (this.imageType === 'BookList') ? title : asin
        const link = urlHelper['baseUrl'] + urlBody + urlHelper['queryParam']

        return link
      },
    }
  }
</script>

<style lang="scss" scoped>
.parent-img{
    position: relative;
}
.parent-img-responsive{
  width:100%;
  height:auto;
}

.child-img{
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  img {
    -webkit-filter: grayscale(100%);
    -webkit-transition: .5s ease-in-out;
    -moz-filter: grayscale(100%);
    -moz-transition: .5s ease-in-out;
    -o-filter: grayscale(100%);
    -o-transition: .5s ease-in-out;
  }
}
</style>
